import React from 'react'
import {graphql} from 'gatsby'
import {Helmet} from 'react-helmet'

import {Answers} from '../components/answers'
import {Breadcrumbs} from '../components/breadcrumbs'
import {Content} from '../components/content'
import {Layout} from '../components/grid'
import {Header} from '../components/header'
import {Questions} from '../components/questions'

interface PageData{
  question: {
    title: string
    description: string
    answers: {
      title: string
      fields: {
        slug: string
      }
    }[]
    fields: {
      breadcrumbs: string
    }
  }

  questions: {
    nodes: {
      title: string
      description: string
      fields: {
        slug: string
      }
    }[]
  }
}

const QuestionTemplate: React.FC<{data: PageData}> = ({data}) => {
  const {question, questions: {nodes: questions}} = data

  return <>
    <Helmet title={question.title} />
    <Header />
    <Layout>
      <Breadcrumbs breadcrumbs={question.fields.breadcrumbs} />
      <h1>{question.title}</h1>
      <Content html={question.description} />
      <Questions questions={questions} />
      <Answers answers={question.answers} />
    </Layout>
  </>
}

export default QuestionTemplate

export const PageQuery = graphql`
  query QuestionTemplateQuery($slug: String, $id: Int){
    question: directusQuestion(fields: {slug: {eq: $slug}}) {
      title
      description
      answers {
        title
        fields{
          slug
        }
      }
      fields{
        breadcrumbs
      }
    }

    questions: allDirectusQuestion(sort: {fields: title}, filter: {directusParent: {id: {eq: $id}}}){
      nodes{
        title
        description
        fields{
          slug
        }
      }
    }
  }
`